import { AuthenticationProxy } from 'Api/Features/Authentication/AuthenticationProxy';
import { AccessTokenGrantType } from 'Api/Features/Authentication/Dtos/AccessTokenGrantType';
import { CreateAccessTokenRequestDto } from 'Api/Features/Authentication/Dtos/CreateAccessTokenRequestDto';
import { CreateAccessTokenResponseDto } from 'Api/Features/Authentication/Dtos/CreateAccessTokenResponseDto';
import { ForgotPasswordRequestDto } from 'Api/Features/Users/Dtos/ForgotPasswordRequestDto';
import { inject } from 'aurelia-dependency-injection';
import { AxiosRequestConfig } from 'axios';
import { HttpClient } from 'HttpClient/HttpClient';
import { AuthenticationStore, AuthorizationStore } from 'Stores';
import { ApiService } from './ApiService';
import { UserService } from './UserService';

@inject(HttpClient, AuthenticationProxy, AuthenticationStore, UserService, AuthorizationStore)
export class AuthenticationService extends ApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly authenticationProxy: AuthenticationProxy,
        private readonly authenticationStore: AuthenticationStore,
        private readonly usersService: UserService,
        private readonly authorizationStore: AuthorizationStore
    ) {
        super();
    }

    public installInterceptors(): void {
        this.httpClient.addRequestInterceptor((requestConfig: AxiosRequestConfig) =>
            this.onBeforeRequest(requestConfig)
        );
    }

    public async initAccessToken(userName: string, password: string): Promise<void> {
        const request: CreateAccessTokenRequestDto = {
            grant_type: AccessTokenGrantType.password,
            username: userName,
            password: password,
            refresh_token: null,
        };
        const response: CreateAccessTokenResponseDto | null =
            await this.authenticationProxy.createAccessToken(request);
        if (response != null) {
            this.authenticationStore.setSession(response);
        }
    }

    public async refreshAccessToken(refreshToken: string): Promise<void> {
        const request: CreateAccessTokenRequestDto = {
            grant_type: AccessTokenGrantType.refresh_token,
            username: null,
            password: null,
            refresh_token: refreshToken,
        };

        const response: CreateAccessTokenResponseDto | null =
            await this.authenticationProxy.createAccessToken(request);
        if (response != null) {
            this.authenticationStore.setSession(response);
        }
    }

    public async refreshAccessTokenIfNeeded(): Promise<void> {
        //Retrieve refresh token from store.
        const refreshToken = this.authenticationStore.refreshToken;
        const tokenExpiration = this.authenticationStore.expirationTimeStamp;

        // No token currently set.
        if (!refreshToken || !tokenExpiration) {
            return;
        }

        if (Math.round(Date.now() / 1000) > tokenExpiration) {
            await this.refreshAccessToken(refreshToken);
        }
    }

    public async onBeforeRequest(requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        if (requestConfig.url !== '/token') {
            await this.refreshAccessTokenIfNeeded();

            //Retrieve access token from store.
            const accessToken = this.authenticationStore.accessToken;

            //Add Authorization header in request
            if (
                requestConfig.headers &&
                requestConfig.url !== '/users/forgot-password' &&
                requestConfig.url !== '/users/set-password'
            )
                requestConfig.headers.Authorization = `bearer ${accessToken}`;
        }
        return requestConfig;
    }

    public async forgottenPassword(email: string): Promise<void> {
        const request: ForgotPasswordRequestDto = {
            email: email,
        };
        await this.usersService.forgotPassword(request);
    }
}
